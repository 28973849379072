// import "./App.css";
// import About from "./pages/about";
// import Contact from "./pages/contact";
// import Feature from "./pages/feature";
// import IndexPage from "./pages";
// import Project from "./pages/project";
// import Quote from "./pages/quote";
// import Service from "./pages/service";
// import Team from "./pages/team";
// import Testimonal from "./pages/testimonal";
// import { BrowserRouter, Routes, Route } from "react-router-dom";

// function App() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<IndexPage />} />
//         <Route path="/home" element={<IndexPage />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/contact" element={<Contact />} />
//         <Route path="/feature" element={<Feature />} />
//         <Route path="/project" element={<Project />} />
//         <Route path="/quote" element={<Quote />} />
//         <Route path="/service" element={<Service />} />
//         <Route path="/team" element={<Team />} />
//         <Route path="/testimonal" element={<Testimonal />} />
//       </Routes>
//     </BrowserRouter>
//   );
// }

// export default App;

import React, { Suspense, lazy } from "react";
import "./App.css";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Navbar from "./components/Navbar";
// import Footer from "./components/Footer";

// Lazy-loaded pages
// const Home = lazy(() => import("./components/Home"));
// const About = lazy(() => import("./components/About"));
// const Services = lazy(() => import("./components/Services"));
// const Contact = lazy(() => import("./components/Contact"));

function App() {
  return (
    <div className="centered moving-text">Under Maintenance...</div>
    // <Router>
    //   <Navbar />

    //   <Suspense fallback={<div>Loading...</div>}>
    //     <Routes>
    //       <Route path="/" element={<Home />} />
    //       <Route path="/home" element={<Home />} />
    //       <Route path="/about" element={<About />} />
    //       <Route path="/service" element={<Services />} />

    //       <Route path="/contact" element={<Contact />} />
    //     </Routes>
    //   </Suspense>
    //   <Footer />
    // </Router>
  );
}

export default App;
